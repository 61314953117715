<template>
    <div>
        <slot name="approveBtn" v-bind:approve="approve"></slot>
        <slot name="deferBtn"></slot>
        <slot name="rejectBtn" v-bind:reject="reject"></slot>
    </div>
</template>

<script>
export default {
  methods: {
    approve() {
      this.$dialog.confirm(
        "Are you sure you want to approve application?",
        {
            okText:"Approve",
            cancelText:"Cancel",
            verification:"continue",
            type:'hard',
            reverse:true
        }
      ).then(()=>{
        this.$emit("approved");
      }).catch(e=>{
        console.log(e);
      })
    },
    reject() {
      this.$dialog.prompt(
        {
          title: "Rejection Notes",
          body: "Comments on rejection"
        },
        {
          promptHelp: 'Type comments in the box below and click "[+:okText]"',
          okText:"Reject",
          cancelText:"Cancel",
          reverse:true
        }
      ).then(dialog=>{
        this.$emit("rejected",dialog);
      }).catch(e=>{
        console.log(e);
      })
    },
  },
};
</script>

<style>
</style>