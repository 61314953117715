<template>
  <b-modal id="new-publication" hide-footer title="Add Publication" size="lg">
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="6">
          <b-form-group label="Tentative Title">
            <b-form-input
              v-model.trim="form.tentative_title"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Edition">
            <b-form-input
              v-model.trim="form.edition"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="First Pub. Year">
            <b-form-input
              v-model="form.first_pub_year"
              type="number"
              min="1800"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Cover Page Title">
            <b-form-input
              v-model.trim="form.cover_page_title"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Publisher">
            <v-select
              :options="publishers"
              taggable
              :reduce="(pub) => pub.id"
              :create-option="(name) => ({ name, id: null })"
              @option:created="addPublisher"
              label="name"
              v-model="form.publisher"
            ></v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Number Of Titles Planned">
            <b-form-input
              v-model="form.number_of_titles"
              type="number"
              min="1"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Nature Of Publication">
            <b-form-select v-model="form.nature">
              <b-form-select-option value=""
                >Please select an option</b-form-select-option
              >
              <b-form-select-option value="single">Single</b-form-select-option>
              <b-form-select-option value="series">Series</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Author">
            <v-select
              :options="authors"
              taggable
              :reduce="(author) => author.id"
              :create-option="(name) => ({ name, id: null })"
              label="name"
              v-model="form.author"
              @option:created="addAuthor"
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!form.author"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
            <b-form-group label="Cover Page Image">
              <b-form-file accept="image/*" v-model="form.cover_page_img" required/>
            </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex">
        <b-button variant="danger" type="submit" class="ml-auto w-25">
          <b-spinner small v-show="saving"></b-spinner>
          SAVE
        </b-button>
      </div>
    </b-form>
    <new-publisher :name="newpub" @new-publ="setPublisher"></new-publisher>
    <new-author :name="newauthr" @new-athr="setAuthor"></new-author>
  </b-modal>
</template>

<script>
import { bus } from '../../main';
import { createApi } from "./api";
import { ApplicationNotFound } from "./exceptions";
import NewPublisher from "../publishers/NewPublisher.vue";
import NewAuthor from '../authors/NewAuthor.vue';

const api = createApi("applications");
export default {
  props: ["application_id"],
  components:{NewPublisher,NewAuthor},
  computed: {
    publishers() {
      return this.$store.getters["newapplication/getFullPublishers"];
    },
    authors() {
      return this.$store.getters["newapplication/getFullAuthors"];
    },
  },
  data() {
    return {
      form: {
        tentative_title: "",
        edition: "",
        number_of_titles: 1,
        cover_page_title: "",
        nature: "",
        first_pub_year: "",
        publisher: "",
        author: "",
        cover_page_img:null
      },
      saving: false,
    };
  },
  methods: {
    onSubmit() {
      this.saving = true;
      // const data = this.form;
      // data["publisher"] = this.addPublisher(this.form);
      // data["author"]=this.addAuthor(this.form);
      api
        .addPublication(this.application_id, this.form)
        .then((pub) => {
          this.saving = false;
          this.$store
            .dispatch("application/savePublication", this.application_id, pub)
            .then(() => this.reset());
          this.$emit("added-publication", pub);
        })
        .catch((err) => {
          if (err instanceof ApplicationNotFound) {
            this.$bvToast.toast(err.message, {
              title: err.name,
              variant:'danger',
            });
            this.$router.push({name:'applications'});
            bus.$emit("reload-applications");
            setTimeout(()=>{
              this.$bvModal.hide("new-publication");  
            },3000)
          }
        });
    },
    addPublisher({ name }) {
      this.newpub = name;
      this.$bvModal.show("new-publisher");
    },
    setPublisher(pub) {
      this.form.publisher = pub.id;
    },
    addAuthor({ name }) {
      this.newauthr = name;
      //hand ctrl to child
      // this.$bvModal.hide("new-application");
      this.$bvModal.show("new-author");
    },
    setAuthor({id}){
        this.form.author = id;
    },
    reset() {
      this.form = {
        tentative_title: "",
        edition: "",
        number_of_titles: 1,
        cover_page_title: "",
        nature: "",
        first_pub_year: "",
        publisher: "",
        author: "",
      };
    },
  },
};
</script>

<style>
</style>