import Vue from "vue";

import { STORES } from ".";
import { PAY_STATUS } from "../applications";
export default (api, type) => {
  return Vue.mixin({
    data() {
      return {
        payment: {
          busy: false,
          fee: 0,
          img: {
            loading: false,
            uri: null,
          },
          verification:{
            loading:false,
          },
          banking:{
            account_no:"",
            name:"",
            branch:"",
          },
          has_banking:false
        },
      };
    },
    methods: {
      async onPay(receipt, application) {
        this.payment.busy = true;
        const payment_receipt = await api.pay(receipt, application.id);
        this.payment.busy = false;
        this.$bvModal.hide('upload-receipt');
        application["payment_receipt"] = payment_receipt;
        this.$store.dispatch(STORES[type] + "/update", application);
        return payment_receipt;
      },
      getPaymentDetails(id) {
        api.getPaymentDetails(id).then((details) => {
          this.payment.fee = details["fee"];
          this.payment.banking = details["banking"];
          this.payment.has_banking = true;
        });
      },
      getReceipt(id) {
        this.payment.img.loading = true;
        api.getReceipt(id).then((uri) => {
          this.payment.img.loading = false;
          if (uri == null) {
            this.$bvToast.toast(`No receipt found for application`, {
              title: "Receipt Missing",
              autoHideDelay: 5000,
              variant: "danger",
            });
          } else {
            this.payment.img.uri = uri;
            this.$bvModal.show("view-receipt");
          }
        });
      },
      verifyReceipt(receipt_no,application){
        this.payment.verification.loading= true;
        api.verifier.settle(application.id,receipt_no).then(()=>{
            this.payment.verification.loading = false;
            application["payment_status"] = PAY_STATUS.PAID; 
            this.$store.dispatch(STORES[type]+"/update",application);
            this.$bvModal.hide('view-receipt');
        }).catch(err=>{
          if(err.response){
            this.$bvToast.toast(err.response.data,{'variant':'danger',title:'Error'});
          }else{
            this.$bvToast.toast('unknown error',{'variant':'danger',title:'Error'});
          }
        }).finally(()=>{
          this.payment.verification.loading = false;
        });
      }
    },
  });
};
