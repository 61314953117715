const APP_TYPES={
    DEPOSIT:'deposit',
    ISBN:'isbn'
}
const STORES = {
   [APP_TYPES.ISBN]:'application',
   [APP_TYPES.DEPOSIT]:'deposit'
}
const DIALOG_STATUS = {
    CANCELED:'canceled'
}
export {APP_TYPES,STORES,DIALOG_STATUS};