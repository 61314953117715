<template>
  <b-modal
    id="the-applicant"
    title="Applicant Details"
    hide-footer
    size="applicant"
  >
    <b-row class="my-1">
      <display-field
        label="Name"
        :field="applicant | fullName"
        class="col-8"
      ></display-field>
      <display-field
        label="Sex"
        :field="applicant.gender"
        class="col-4"
      ></display-field>
      <display-field
        label="Postal"
        :field="applicant.postal_code | fillNA"
        class="col-4"
      ></display-field>
    </b-row>
    <b-row class="my-1">
      <display-field
        label="Email Address"
        :field="applicant.email"
        class="col-8"
      ></display-field>
      <display-field
        label="Mobile"
        :field="applicant.mobile_no"
        class="col-4"
      ></display-field>
    </b-row>
    <b-row class="my-1">
      <display-field
        label="District"
        :field="applicant.district"
        class="col-4"
      ></display-field>
      <display-field
        label="Parish"
        :field="applicant.parish | fillNA"
        class="col-4"
      ></display-field>
      <display-field
        label="County"
        :field="applicant.county | fillNA"
        class="col-4"
      ></display-field>
      <display-field
        label="SubCounty"
        :field="applicant.subcounty | fillNA"
        class="col-4"
      ></display-field>
    </b-row>
  </b-modal>
</template>

<script>
import DisplayField from "./publication/DisplayField.vue";
export default {
  props: ["applicant"],
  components: { DisplayField },
  filters: {
    fullName({ first_name, last_name }) {
      return first_name + " " + last_name;
    },
    fillNA(field) {
      return field ? field : "NA";
    },
  },
};
</script>

<style>
.modal .modal-applicant {
  max-width: 29em;
  width: 29em;
}
</style>