<template>
  <b-modal title="Defer Application/Deposit" id="defer-application" hide-footer v-model="show">
    <h6>Comments</h6>
    <p>Clearly state the reasons (max. 500 characters)</p>
    <b-form @submit.prevent="onSubmit">
        <b-form-textarea v-model="notes" placeholder="Your comments here" rows="7" maxlength="500" required>
        </b-form-textarea>
        <div class="defer-btn-group mt-2">
            <b-button variant="outline-danger" class="defer-btn" @click="onCancel">CANCEL</b-button>
            <b-button variant="danger" class="defer-btn submit-btn" type="submit">SUBMIT</b-button>
        </div>
    </b-form>
  </b-modal>
</template>

<script>
export default {
    data(){
        return {
            notes:'',
            show:false
        }
    },
    methods:{
        onSubmit(){
            this.$emit("defered",this.notes);
            this.notes = "";
            this.onCancel();
        },
        onCancel(){
            this.show = false;
        }
    }
}
</script>

<style scoped>
.defer-btn-group{
    width:18em;
    margin-left:auto;
}
.defer-btn{
    width:8em;
}
.submit-btn{
    margin-left: 1em;    
}
</style>
