<template>
    <b-modal id="add-isbn" title="Issue Isbn" hide-footer>
        <b-form @submit.prevent="onSubmit">
            <b-form-group label="ISBN (without -)">
                <b-form-input type="text" v-model="form.isbn"></b-form-input>
            </b-form-group>
            <b-form-group label="ISBN image">
                <b-form-file v-model="form.isbn_file" accept=".png"></b-form-file>
            </b-form-group>
            <b-button class="float-right" variant="danger" type="submit">
                <b-spinner small v-show="addingIsbn" variant="light"></b-spinner> &nbsp;
                SAVE
            </b-button>
        </b-form>
    </b-modal>
</template>
<script>
export default {
    props:["addingIsbn"],
    data(){
        return {
            form:{
                isbn_file:null,
                isbn:''
            }
        }
    },
    methods:{
        onSubmit(){
            let formdata = new FormData();
            formdata.set("isbn",this.form.isbn);
            formdata.append("isbn_file",this.form.isbn_file);
            this.$emit("issue-isbn", formdata);
        }
    }
    
}
</script>