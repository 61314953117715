<template>
  <div>
    <div class="my-2">
      <b-button variant="outline-danger" pill size="sm"> Reload </b-button>
    </div>
    <b-row style="align-items: flex-start">
      <b-col cols="6">
        <div class="app-list">
          <b-list-group>
            <b-list-group-item
              :class="{ active_application: appln.id == application.id }"
              class="flex-column align-items-start mb-1 rounded-5"
              v-for="appln in pagedApplications"
              @click="onload(appln.id)"
              :key="appln.id"
            >
              <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-1 font-weight-bold">{{ appln.drn }}</h6>
                <small>{{ appln.created_at | fmt_time }}</small>
              </div>

              <p class="my-2">
                {{ appln.publications | fmt_title }}
              </p>

              <b-badge
                :variant="appln.payment_status | paint_payment"
                class="text-uppercase"
                >{{ appln.payment_status | fmt_pay }}</b-badge
              >
            </b-list-group-item>
          </b-list-group>
        </div>
        <b-pagination
          v-model="currentAppPage"
          :total-rows="applications.length"
          :per-page="4"
          page-class="customPagination"
        ></b-pagination>
      </b-col>
      <b-col cols="6">
        <div class="px-2 bg-light pt-2 pub-container">
          <div
            class="
              border-bottom-1 border-secondary
              mb-2
              d-flex
              align-items-center
            "
          >
            <div class="app-options d-flex">
              <b-button
                variant="outline-danger"
                class="mr-1"
                :disabled="!$can('delete_application')"
                v-show="application.status == 'unverified'"
                @click="deleteApplication"
              >
                <b-icon-trash v-show="!deleting"></b-icon-trash>
                <b-spinner small v-show="deleting"></b-spinner>
              </b-button>
              <b-button
                variant="outline-danger"
                class="mr-1"
                @click="getApplicant"
                :disabled="!$can('view_application')"
                size="sm"
              >
                <b-icon-person v-show="!applicanting"></b-icon-person>
                <b-spinner small v-show="applicanting"></b-spinner>
              </b-button>
              <b-button
                class="mb-1"
                variant="outline-danger"
                id="bank-details"
                size="sm"
                v-show="$can('make_payment') && payment.has_banking"
              >
                <b-icon-bank></b-icon-bank>
              </b-button>
            </div>
            <div style="width: 8em" class="ml-auto">
              <h6 class="font-weight-bold text-center">
                {{ application.drn }}
              </h6>
            </div>
            <div>
              <h6 class="text-muted text-right">
                {{ application.created_at | fmt_time }}
              </h6>
            </div>
          </div>
          <b-button
            variant="danger"
            class="mb-1 mr-1"
            :disabled="!can_delete_publication()"
            v-show="$can('edit_application') && ['unverified','defered/approver'].includes(application.status)"
            size="sm"
            v-b-modal.new-publication
          >
            <b-icon-plus></b-icon-plus>Publication
          </b-button>
          <b-dropdown
            id="receipt-options"
            text="Receipt Menu"
            size="sm"
            class="mb-1"
            variant="outline-danger"
            offset="25"
          >
            <b-dropdown-item
              v-show="
                $can('make_payment') && is_not_paid(application.payment_status)
              "
              v-b-modal.upload-receipt
            >
              <b-icon-upload></b-icon-upload> Receipt
            </b-dropdown-item>
            <b-dropdown-item
              @click="getReceipt(application.id)"
              :disabled="application.payment_receipt == null"
            >
              <b-icon-eye v-show="!payment.img.loading"></b-icon-eye>
              <b-spinner v-show="payment.img.loading" small></b-spinner>
              Receipt
            </b-dropdown-item>
          </b-dropdown>
          <b-button variant="danger" class="mb-1 ml-1" 
          v-show="can_view_comments()" 
          @click="getComments"
          size="sm">
            <b-icon-card-text></b-icon-card-text> Comments
          </b-button>
          <b-button
            variant="outline-danger"
            class="mb-1 mx-1"
            v-show="$can('verify_application')"
            :disabled="
              application.status == 'verified' ||
              is_not_paid(application.payment_status)
            "
            @click="onVerify"
            size="sm"
          >
            <b-icon-check v-show="!verifying"></b-icon-check> Verify
            <b-spinner small v-show="verifying"></b-spinner>
          </b-button>
          <b-button
            variant="danger"
            class="mb-1 ml-1"
            @click="onRefute"
            v-show="$can('refute_application')"
            size="sm"
          >
            <b-icon-x v-show="!refuting"></b-icon-x> Reject
            <b-spinner small v-show="refuting"></b-spinner>
          </b-button>
          <approve-reject @approved="onApprove" @rejected="onReject">
            <template v-slot:approveBtn="props">
              <b-button
                variant="outline-success"
                class="mb-1 ml-1"
                v-show="$can('approve_application') && application.status != 'approved'"
                @click="props.approve"
                size="sm"
              >
                <b-icon-check v-show="!approval.approving"></b-icon-check>
                <b-spinner v-show="approval.approving" small></b-spinner>
                Approve
              </b-button>
            </template>
            <template v-slot:deferBtn>
              <b-button
                class="mb-1 ml-1"
                v-show="$can('defer_application') && application.status != 'approved'"
                v-b-modal.defer-application
                variant="outline-danger"
                size="sm"
              >
                <b-icon-x v-show="!approval.defering"></b-icon-x>
                <b-spinner v-show="approval.defering" small></b-spinner>
                Defer
              </b-button>
              <defer @defered="onDefer"></defer>
            </template>
            <template v-slot:rejectBtn="props">
              <b-button
                variant="danger"
                class="mb-1 ml-1"
                v-show="$can('refuse_application') && (!['rejected/approver','approved'].includes(application.status))"
                @click="props.reject"
                size="sm"
              >
                <b-icon-x v-show="!approval.rejecting"></b-icon-x>
                <b-spinner v-show="approval.rejecting" small></b-spinner>
                Reject
              </b-button>
            </template>
          </approve-reject>
          <div class="bg-light pub-list mb-1">
            <b-card
              no-body
              class="overflow-hidden publication-card mb-1"
              v-for="publication in publications"
              :key="publication.id"
            >
              <div>
                <b-card-body
                  :title="publication.tentative_title"
                  title-tag="h5"
                  :sub-title="
                    publication.cover_page_title +
                    ' | ' +
                    publication.edition +
                    ' edition'
                  "
                >
                  <div
                    v-show="application.status == 'approved'"
                    style="font-size: small"
                    class="mb-1"
                  >
                    <b-icon-book></b-icon-book> ISBN:
                    {{ publication.isbn | fmt_isbn }}
                    <a v-if="publication.isbn" :href="baseStatic+'/'+publication['isbn_file']" target="_blank">
                      <b-icon-arrow-down></b-icon-arrow-down></a>
                  </div>

                  <div class="d-flex w-100">
                    <b-col cols="4" class="p-0">
                      <b-button-group size="sm">
                        <b-button
                          variant="outline-danger"
                          class="mr-1"
                          :disabled="!can_delete_publication()"
                          v-show="$can('edit_application')"
                          @click="dropPublication(publication)"
                        >
                          <b-icon-trash></b-icon-trash>
                        </b-button>
                        <b-button
                          variant="outline-danger"
                          @click="viewMore(publication)"
                          class="mr-1"
                          :disabled="!application.loadedPubs"
                        >
                          <b-icon-eye></b-icon-eye>
                        </b-button>
                        <b-button
                          @click="issueIsbn(publication)"
                          v-show="
                            $can('approve_application') &&
                            application.status == 'approved'
                          "
                          variant="outline-danger"
                        >
                          <span style="font-size: x-small">&plus; ISBN</span>
                        </b-button>
                      </b-button-group>
                    </b-col>
                    <b-col cols="3"> </b-col>
                    <b-col cols="5" class="justify-self-end">
                      <div
                        class="d-flex align-items-center justify-space-between"
                      >
                        <b-avatar
                          variant="secondary"
                          size="sm"
                          class="mr-2"
                          icon="person"
                        ></b-avatar>
                        <div class="w-75">
                          <b-skeleton
                            animation="wave"
                            width="100"
                            v-if="!application.loadedPubs"
                          ></b-skeleton>
                          <div
                            v-else
                            style="max-width: 20ch"
                            class="text-truncate"
                          >
                            {{ publication["author"]["name"] }}
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </div>
                </b-card-body>
              </div>
            </b-card>
          </div>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="application.publications.length"
          :per-page="2"
          class="pos-pagtn"
          page-class="customPagination"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-alert variant="danger" :show="application.payment_receipt == null">
      Application has not been paid. Click <b-icon-bank></b-icon-bank> to view payment details.&nbsp;
      Click Receipt Menu and upload receipt if payment has been made.
    </b-alert>
    <new-publication
      @added-publication="savePub"
      :application_id="application.id"
    ></new-publication>

    <publication :publication="publication"></publication>
    <applicant :applicant="applicant"></applicant>
    <receipt
      :busy="payment.busy"
      @upload="
        (rcpt) => {
          onPay(rcpt, application);
        }
      "
    ></receipt>
    <view-receipt
      :src="payment.img.uri"
      :drn="application.drn"
      :busy="payment.verification.loading"
      @verify="
        (receipt_no) => {
          verifyReceipt(receipt_no, application);
        }
      "
    ></view-receipt>
    <add-isbn ref="isbn_adder" :addingIsbn="addingIsbn"></add-isbn>
    <bank-vue :banking="payment.banking" :fees="application.fees()"></bank-vue>
    <notes-vue :body="notes.body" :title="notes.title" :busy="notes.busy"></notes-vue>
  </div>
</template>

<script>
import moment from "moment";
import { PAY_STATUS, APPL_STATUS } from "..";
import { createApi, Application } from "../api";
import NewPublication from "../NewPublication.vue";
import Publication from "../Publication.vue";
import AppMixin from "../mixins";
import VerifyMixin from "../../shared/VerifyMixin";
import PaymentMixin from "../../shared/PaymentMixin";
import Applicant from "../Applicant.vue";
import { APP_TYPES } from "../../shared";
import ApproveReject from "../../../components/ApproveReject.vue";
import { AlreadyApproved, ApplicationNotFound } from "../exceptions";
import { bus } from "../../../main";
import Receipt from "../../../components/Receipt.vue";
import ViewReceipt from "../../../components/ViewReceipt.vue";
import BankVue from "../../../components/Bank.vue";
import AddIsbn from "../../../components/AddIsbn.vue";
import Defer from "../../../components/Defer.vue";
import NotesVue from "../../../components/Notes.vue";
// import {isValidISBN} from "../facades/isbn";
const api = createApi("applications");

export default {
  mixins: [AppMixin, VerifyMixin, PaymentMixin(api, APP_TYPES.ISBN)],
  components: {
    AddIsbn,
    NewPublication,
    Publication,
    Applicant,
    ApproveReject,
    Receipt,
    ViewReceipt,
    BankVue,
    Defer,
    NotesVue
  },
  filters: {
    /**
     *
     * @param {string} value
     */
    fmt_isbn(value) {
      if (value == null) return "XXX-XXX-XXX-XXXX";
      return value;
    },
    fmt_title(publications) {
      if (publications.length == 1) return publications[0]["tentative_title"];
      if (publications.length > 1)
        return (
          publications[0]["tentative_title"] +
          `+ ${publications.length - 1} more`
        );
    },
    fmt_time(cdate) {
      return moment(cdate).format("MMM DD");
    },
    paint_payment(status) {
      return status == PAY_STATUS.PAID ? "success" : "danger";
    },
    fmt_pay(status) {
      return status == PAY_STATUS.PENDING ? "unpaid" : PAY_STATUS.PAID;
    },
  },
  computed: {
    applications() {
      const appls = this.$store.getters["application/getApplications"];
      let status = this.$route.query["status"];
      return status == undefined
        ? appls
        : appls.filter((el) => el["status"] == status);
    },
    pagedApplications() {
      const page = this.currentAppPage - 1;
      return this.applications.slice(page * 4, this.currentAppPage * 4);
    },
    publications() {
      const page = this.currentPage - 1;
      return this.application.publications.slice(
        page * 2,
        this.currentPage * 2
      );
    },
  },
  data() {
    return {
      baseStatic: process.env.VUE_APP_STATIC,
      addingIsbn:false,
      approval: {
        approving: false,
        rejecting: false,
        defering: false,
      },
      notes:{
        title:"",
        body:"",
        busy:false
      },
      applicant: {
        first_name: "",
        last_name: "",
        email: "",
        parish: "",
        county: "",
        district: "",
        subcounty: "",
        postal_code: "",
        gender: "",
      },
      currentAppPage: 1,
      applicanting: false,
      verifying: false,
      refuting: false,
      publication: {
        id: "",
        tentative_title: "",
        cover_page_title: "",
        number_of_titles: "",
        author: { name: "" },
        nature: "single",
        publisher: { name: "" },
      },
      currentPage: 1,
      issue_isbn:null,
      application: new Application({
        id: 0,
        loadedPubs: false,
        publications: [],
        status: APPL_STATUS.UNVERIFIED,
      }),
    };
  },
  mounted() {
    const application_id = this.$route.params["application_id"];
    this.onload(application_id);
    this.getPaymentDetails(application_id);
    this.type = APP_TYPES.ISBN;
    this.api = api;
    this.prompt = {
      verification: {
        title: "Verification Notice",
        body: "Are you sure you want to verify this application?",
      },
      rejection: {
        title: "Rejection Notice",
        body: "Comments on application",
      },
    };
  },
  methods: {
    getComments(){
      let resolver = null;
    
      this.notes.busy = true;
      if([APPL_STATUS.DEFER_APPROVER,APPL_STATUS.REJECTED_APPROVER].includes(this.application.status)){
        this.notes.title = "Approver Comments";
        resolver = api.approver.comments(this.application.id);
      }else if(this.application.status==APPL_STATUS.REJECTED_VERIFIER){
        this.notes.title = "Verifier Comments";
        resolver = api.verifier.comments(this.application.id);
      }

      if(resolver){
        this.$bvModal.show('the-notes');
        resolver.then(body=>{
          this.notes.body = body;
          this.notes.busy = false;
        });
      }
    },
    can_delete_publication() {
      return (
        this.$can("edit_application") &&
        [
          APPL_STATUS.DEFER_APPROVER,
          APPL_STATUS.REJECTED_VERIFIER,
          APPL_STATUS.UNVERIFIED,
        ].includes(this.application.status)
      );
    },
    can_view_comments(){
      return [APPL_STATUS.DEFER_APPROVER,APPL_STATUS.REJECTED_APPROVER,APPL_STATUS.REJECTED_VERIFIER].includes(this.application.status);
    },
    is_not_paid(status) {
      return status != PAY_STATUS.PAID;
    },
    /**
     *
     * @param {{application_id:number,id:number,isbn:string}} publication
     */
    issueIsbn(publication) {
        this.$bvModal.show("add-isbn");

        const cb = (formd)=>{
          this.addingIsbn = true;
          this.$store.dispatch("application/modifyPublication", {
              action: "setIsbn",data: {isbn_form: formd,pub_id: publication.id,
                application_id: publication.application_id,
              },
            })
            .then(({isbn_file}) => {
              publication["isbn"] = formd.get("isbn");
              publication["isbn_file"] = isbn_file;
              this.addingIsbn = false;
              this.$bvModal.hide("add-isbn");
            });
        }
        const widget = this.$refs["isbn_adder"];
        if(!this.issue_isbn){
          widget.$off("issue-isbn",this.issue_isbn);
          this.issue_isbn = cb;
        }
        widget.$on("issue-isbn",this.issue_isbn);
    },
    onDefer(notes) {
      this.approval.defering = true;
      api.approver.defer(this.application.id, notes).then(() => {
        this.approval.defering = false;
        this.$store
          .dispatch("application/update", {
            ...this.application,
            status: APPL_STATUS.DEFER_APPROVER,
          })
          .then(() => {
            this.$router.push({
              path: "/",
              query: { status: APPL_STATUS.DEFER_APPROVER },
            });
          });
      });
    },
    onApprove() {
      this.approval.approving = true;
      api.approver
        .approve(this.application.id)
        .then(() => {
          this.approval.approving = false;
          this.$store
            .dispatch("application/approveApplication", this.application.id)
            .then(() => {
              this.$router.go(-1);
            });
        })
        .catch((err) => {
          if (err instanceof AlreadyApproved) {
            this.$dialog
              .alert({ title: err.name, body: err.message })
              .then(() => {
                bus.$emit("reload-applications");
                this.$router.push({ name: "applications" });
              });
          }
        });
    },
    /**
     *
     * @param {string} comments
     */
    onReject(comments) {
      this.approval.rejecting = true;
      api.approver
        .reject(this.application.id, comments)
        .then(() => {
          this.approval.rejecting = false;
          this.$store
            .dispatch("application/rejectApplication", this.application.id)
            .then(() => {
              this.$router.go(-1);
            });
        })
        .catch((err) => {
          if (err instanceof AlreadyApproved) {
            this.$dialog
              .alert({ title: err.name, body: err.message })
              .then(() => {
                bus.$emit("reload-applications");
                this.$router.push({ name: "applications" });
              });
          }
        });
    },
    onload(application_id) {
      const application = this.applications.find(
        (el) => el["id"] == application_id
      );
      this.application = { ...application, loadedPubs: false };
      this.loadPubs();
    
    },
    loadPubs() {
      api.getPublications(this.application.id).then((data) => {
        this.application = new Application({
          ...this.application,
          publications: data,
          loadedPubs: true,
        });
      });
    },
    viewMore(pub) {
      this.publication = pub;
      this.$bvModal.show("the-publication");
    },
    savePub(pub) {
      this.application.savePublication(pub);
    },

    deleteApplication() {
      this.dropApplication(this.application.id)
        .then((status) => {
          if (status) {
            this.$store
              .dispatch("application/dropApplication", this.application.id)
              .then(() => {
                this.$router.go(-1);
              });
          }
        })
        .catch((err) => {
          if (err instanceof ApplicationNotFound) {
            bus.$emit("reload-applications");
            this.$router.push({ name: "applications" });
          }
        });
    },
    dropPublication(publication){
      this.$dialog.confirm(
        "Are you sure you want to drop title from application?",
        {
            okText:"Delete Publication",
            cancelText:"Cancel",
            reverse:true
        }
      ).then(()=>{
        this.$store
            .dispatch("application/modifyPublication", {
              action: "drop",
              data: {
                pub_id: publication.id,
                application_id: publication.application_id,
              },
            })
            .then(() => {
              this.application["publications"] = this.application["publications"].filter(pub=>pub['id']!=publication.id);
              this.getPaymentDetails(publication.application_id);
            });
      }).catch(e=>{
        console.log(e);
      })
    },
    getApplicant() {
      this.applicanting = true;
      api.getApplicant(this.application.id).then((data) => {
        this.applicanting = false;
        this.applicant = data;
        this.$bvModal.show("the-applicant");
      });
    },
  },
};
</script>

<style lang="scss">
$list-height: 70vh;
.active_application {
  background-color: #e2e2e2;
  color: #606060;
}
.app-options {
  width: 10em;
}
.publication-card {
  width: 100%;
}
.app-list {
  min-height: $list-height;
}
.pub-list {
  max-height: calc(#{$list-height}-18vh);
  overflow-y: auto;
}
.pub-container {
  min-height: $list-height;
}
.customPagination > button {
  color: var(--dash-red);
}

.page-item.active.customPagination > button,
.customPagination > button:hover,
.customPagination > button:focus {
  color: white;
  background-color: var(--dash-red);
  border: gray;
}
.pos-pagtn {
  margin-top: auto;
  height: fit-content;
}
</style>
