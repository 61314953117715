<template>
  <b-modal id="view-receipt" title="View Receipt" hide-footer no-close-on-backdrop>
    <div class="row">
      <div class="col-6">
        <img :src="src" class="receipt-img" />
      </div>
      <div class="col-6">
        <div v-show="$can('make_payment')">
         <p class="text-justify congs-remarks">Dear {{name}}, The receipt for <b>Application {{drn}}</b> was received and yet to be verified. The National Library thanks you for your support.</p> 
        </div>
        <form class="mt-1" v-show="$can('verify_payment')" @submit.prevent="verify">
          <h6>Hello, {{name}}</h6>
          <p class="text-justify">Download the receipt image to read off receipt no.</p>
          <b-form-group label="To verify payment">
            <b-input
              type="text"
              v-model="receipt_no"
              placeholder="Type the receipt no here"
            ></b-input>
          </b-form-group>
          <b-button class="w-100" type="submit" variant="outline-danger">
            <b-spinner small v-show="busy"></b-spinner>
            Verify Payment
        </b-button>
        </form>
        <div class="mt-1">
          <b-button class="w-100" variant="danger" @click="download">Download Receipt</b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["src","drn","busy"],
  computed: {
    name() {
      return this.$store.state.auth.name;
    },
  },
  data(){
    return {
        receipt_no:''
    }
},
  methods: {
    download() {
      var link = document.createElement("a");
      link.href = this.src;
      link.download = "receipt.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    verify(){
        this.$emit("verify",this.receipt_no);
    }
  },
};
</script>

<style scoped>
.receipt-img {
  object-fit: cover;
  height: 17em;
  width: 15em;
}
.congs-remarks{
    line-height: 1.7em;
}
</style>