<template>
  <b-modal title="Upload Receipt" hide-footer id="upload-receipt" size="sm">
    <form @submit.prevent="upload">
        <b-form-file placeholder="Upload Receipt" @change="selectFile"></b-form-file>
        <div class="row mt-2">
            <div class="col-7">
                <b-button type="submit" class="w-100" variant="danger">
                    <b-spinner small v-show="busy"></b-spinner>
                    SAVE
                </b-button>
            </div>
            <div class="col-5">
                <b-button  class="w-100" variant="outline-danger">RESET</b-button>
            </div>
        </div>
    </form>
  </b-modal>
</template>

<script>
export default {
    props:["busy"],
    data(){
        return {
            fyl:null
        }
    },
    methods:{
        selectFile(e){
           this.fyl =  e.target.files[0];
        },
        reset(){
            this.fyl = null;
        },
        upload(){
            this.$emit("upload",this.fyl);
        }
    }
};
</script>

<style scoped>

</style>
