import Vue from "vue";
import { STORES } from ".";
import { bus } from "../../main";
import { AlreadyVerified } from "../applications/exceptions";
import { APPL_STATUS } from "../applications";
const ROUTES = {
  isbn: "applications",
  deposit: "deposits",
};

export default Vue.mixin({
  data(){
    return {
      verifier_comments: {
        loading: true,
        body: "",
      },
    }
  },
  methods: {
    onRefute() {
      this.refuting = true;
      this.$dialog
        .prompt(this.prompt.rejection, {
          reverse: true,
          okText: "Reject",
          cancelText: "Cancel",
          promptHelp:
            'Type in the box below for comments and click "[+:okText]"',
        })
        .then((dialog) => {
          this.api.verifier
            .reject(this.application.id, dialog.data)
            .then(() => {
              this.refuting = false;
              this.$store
                .dispatch(STORES[this.type] + "/update", {
                  ...this.application,
                  status: APPL_STATUS.REJECTED_VERIFIER,
                })
                .then(() => {
                  this.$router.push({ name: ROUTES[this.type] });
                });
            });
        })
        .catch(() => {
          // Triggered when dialog is dismissed by user
          this.refuting = false;
        });
    },
    onVerify() {
      this.verifying = true;
      this.$dialog
        .confirm(this.prompt.verification, {
          reverse: true,
          okText: "Verify",
          cancelText: "Cancel",
        })
        .then((dialog) => {
          this.api.verifier
            .verify(this.application.id, dialog.data)
            .then(() => {
              this.verifying = false;
              this.$store
                .dispatch(STORES[this.type] + "/update", {
                  ...this.application,
                  status: APPL_STATUS.VERIFIED,
                })
                .then(() => {
                  this.$router.push({ name: ROUTES[this.type] });
                });
            })
            .catch((err) => {
              if (err instanceof AlreadyVerified) {
                bus.$emit("reload-" + err.app_type);
                this.$dialog
                  .alert({ title: err.name, body: err.message })
                  .then(() => {
                    this.$router.push({ name: ROUTES[this.type] });
                  });
              }
            });
        })
        .catch(() => {
          // Triggered when dialog is dismissed by user
          this.verifying = false;
        });
    },
    loadComments() {
      this.verifier_comments.loading = true;
      this.api.verifier.comments(this.application.id).then((body) => {
        this.verifier_comments = { loading: false, body };
      });
    },
  },
});
