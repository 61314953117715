<template>
  <div>
    <div class="font-weight-bold">{{label}}</div>
    <div>{{field}}</div>
  </div>
</template>

<script>
export default {
    props:["label","field"]
}
</script>

<style>

</style>