<template>
  <b-modal id="the-notes" :title="title" size="md" hide-footer>
    <div class="notes-body" v-show="!busy">
      {{ body }}
    </div>
    <div v-show="busy">
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="75%"></b-skeleton>
        <b-skeleton animation="wave" width="50%"></b-skeleton>
      </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    title: String,
    body: String,
    busy:Boolean
  },
};
</script>

<style scoped>
.notes-body {
  line-height: 1.68em;
  text-align: justify;
  letter-spacing: 0.03em;
}
</style>