<template>
 <b-popover target="bank-details" size="sm">
    <template #title>Bank Details</template>
        <div class="payment-detail">
                <div>Name</div>
                <div>
                    {{banking.name}}
                </div>
            </div>
            <div class="payment-detail">
                <div>Acc No.</div>
                <div>{{banking.account_no}}</div>
            </div>
            <div class="payment-detail">
                <div>Branch</div>
                <div>{{banking.branch}}</div>
            </div>
            <div class="payment-detail">
                <div>Fees</div>
                <div>UGX {{fees.toLocaleString()}}</div>
            </div>
 </b-popover>
</template>

<script>
export default {
    props:["banking","fees"]
}
</script>

<style>
.payment-detail{
    display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 15em;
}
</style>