import { DIALOG_STATUS } from ".";
import { createApi } from "../applications/api";
/**
 *
 * @param {string} type
 */
function generateMixin(type) {
  const api = createApi(type);
  return {
    data() {
      return {
        deleting: false,
      };
    },
    methods: {
      async dropApplication(id) {
        let deleted = false;
        this.deleting = true;
        try {
          await this.$dialog.confirm({body:"Are you sure you would like to delete "+type+"? \n"+"Deleted items are not recoverable",title:'Warning'},
          {okText: 'Yes, I comfirm',cancelText:'Cancel'}
          );
          await api.dropApplication(id);
          deleted = true;
        } catch (error) {
          if(error==false){
            throw DIALOG_STATUS.CANCELED;
          }
        }
        this.deleting = false;
        return deleted;
      },
    },
  };
}
export default generateMixin;
