<template>
  <b-modal id="the-publication" title="Publication Details" hide-footer>
    <div>
        <b-row>
            <display-field class="col-8" label="Tentative Title" :field="publication.tentative_title">
            </display-field>
            <display-field class="col-2" label="Edition" :field="publication.edition"></display-field>
            <display-field class="col-2" label="Year" :field="publication.first_pub_year"></display-field>
        </b-row>
        <b-row class="my-3">
            <display-field class="col-8" label="Cover Page Title" :field="publication.cover_page_title"></display-field>
            <display-field class="col-4" label="Publisher" :field="publication.publisher['name']"></display-field>
        </b-row>
        <b-row>
            <display-field class="col-8" label="Number Of Titles Planned" :field="publication.number_of_titles"></display-field>
            <display-field class="col-4" label="Nature" :field="publication.nature"></display-field>
        </b-row>
        <b-row>
          <display-field :field="publication.author['name']" label="Author"></display-field>
        </b-row>
        <b-img :src="img_base+publication.cover_page_img" width="200" height="300"></b-img>

    </div>
  </b-modal>
</template>

<script>
import DisplayField from './publication/DisplayField.vue'
export default {
  components: { DisplayField },
    props:["publication"],
  data(){
    return {
      img_base:process.env.VUE_APP_BACKEND+'/../storage/',
    }
  }
}
</script>

<style>

</style>